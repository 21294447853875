/* Navigasi */

/* .navbar-collapse ul{
    overflow: hidden;
    top: 0;
    position: fixed;
    z-index: 1;
} */
body{
    font-feature-settings: "kern";
}
h1,h2,h3,h4,h5{
    margin-bottom: 0px;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto, input{
    padding: 0px;
}
p{
    margin-bottom: 0px;

}
*, *::before, *::after{
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    border-color: #e2e8f0;
    /* word-wrap: break-word; */
}

.portfolio-text{
    color: #2d3748;
    line-height: 1.625;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; this command line using for make .. if text overload from width page*/
    margin: 0;
}

main{
    padding-top: 5rem;
}
.navbar{
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.64);
    min-height: 60px;
}
@media screen and (min-width: 768px){
    .navbar{
        display: block;
    }
}
.nav-item{
    padding: 10px;
}
.navbar-nav{
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.navbar-nav a{
    /* font-family: "Avenir Next", sans-serif; */
    font-size: 0.9rem;
    text-decoration: none;
    color: #333;
    width: 100px;
    text-align: center;
    justify-content: center;
    background-color: transparent;
}
.navbar-nav a:hover{
    font-weight: 600;
    border-radius: 5px;
}


/* Content */
@media screen and (min-width: 768px){
    .my-section{
        min-height: calc(100vh - 100px);
    }
}
.__my-container{
    width: 100%;
    display: grid;
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: 56rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-bottom: 1rem;
}
.my-section{
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    flex-direction: column;
    min-height: calc(-60px + 100vh);
}
.my-pict{
    overflow: hidden;
    border-radius: 9999px;
    width: 10rem;
    height: 10rem;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-top: 2.5rem;
    margin-bottom: 5rem;
    -webkit-margin-start: auto;
    -webkit-margin-end: auto;
    display: block;
}
@media screen and (min-width: 768px){
    .my-pict{
        width: 12rem;
        height: 12rem;
    }
}
.my-pict__stack img{
    max-width: 100%;
    height: auto;
}
.my-pict__stack{
    top: 0;
    left: 0;
}
/* Membuat gambar zoom ketika mouse hover ke pict */

.my-pict__stack img{
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    width: 220px;
}
.my-pict:hover .my-pict__stack img{
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
}

.my-name{
    font-size: 60px;
    font-weight: 700;
    letter-spacing: -0.005em;
    line-height: 1;
}
.my-desc-profil{
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
}
.my-desc-button{
    display: flex;
    flex-direction: row;
}
.__my-button-desc{
    text-align: center;
    width: 150px;
    height: 40px;
    border: 1px solid #e2e8f0;
    border-radius: 7px;
    font-weight: 500;
    color: #000;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    appearance: none;
    justify-content: center;
    vertical-align: middle;
    transition-property: background-color, border-color,color,fill,stroke,opacity,box-shadow,transform;
    transition-duration: 200ms;
}
.my-desc-button .my-resume:hover{
    text-decoration: none;
    font-weight: 600;
    /* width: 160px;
    height: 45px; */
    border: 1px solid #dfcbcb;
}
.my-desc-button .my-contact:hover{
    text-decoration: none;
    font-weight: 600;
    /* width: 160px;
    height: 45px; */
    border: 1px solid #dfcbcb;
}
.my-button-icon{
    display: inline-flex;
    align-self: center;
    flex-shrink: 0;
    margin-inline-start: 0.5rem;
}
.my-svg-button{
    font-size: 1rem;
}
.my-contact{
    display: inline-flex;
    flex-shrink: 0;
    margin-inline-start: 1rem;
    background-color: #0a0a0a;
    color: #e2e8f0;
}
.__linkme{
    color: #2b6cb0;
    font-weight: 500;
    border-bottom: 2px dotted;
}
.__linkme:hover{
    text-decoration: none;
}
/* .my-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my-right{
    flex: 1;
    position: relative;
}
.my-left-profil{
    padding: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.my-title{
    height: 40px;
    overflow: hidden;
}
.my-title-wrapper{
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}



@keyframes move {
    25%{
        transform: translateY(-50px);
    }
    50%{
        transform: translateY(-100px);
    }
    75%{
        transform: translateY(-150px);
    }
}
.my-title-item{
    font-size: 30px;
    font-weight: bold;
    color: #2756a3;
    display: flex;
    align-items: center;
    
}
.my-bg{
    clip-path: circle(30% at 50% 40%);  */
    /* clip-path : membuat background bentuk bangun ruang */
    /* background-color: #2756a3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}
.my-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
} */
.__hr-css{
    opacity: 0.6;
    border-width: 0px 0px 1px;
    border-image: initial;
    border-color: inherit;
    border-style: solid;
    width: 100%;
    margin-bottom: 1rem;
}


/* About styling */
.__about-css{
    width: 100%;
    -webkit-margin-start: auto;
    margin-inline-start: auto;
    -webkit-margin-end: auto;
    margin-inline-end: auto;
    max-width: 56rem;
    display: grid;
    -webkit-padding-start: 1rem;
    padding-inline-start: 1rem;
    -webkit-padding-end: 1rem;
    padding-inline-end: 1rem;
    padding-bottom: 1rem;
}
.about{
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    gap: 2rem;
}
.about-image{
    position: relative;
    width: 100%;
}
.about-image::before{
    height: 0px;
    content: "";
    display: block;
    padding-bottom: 56.25%;
}
.about-image>img{
    object-fit: cover;
}
.about-image>*:not(style){
    overflow: hidden;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-align: center;
    width: 100%;
    height: 100%;
}
.a-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0px;
    color:transparent;
}
.about-direction{
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: justify;
}
.about-title{
    font-weight: 400;
}
.about-text{
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    font-family: system-ui;
    font-style: normal;
    font-feature-settings: "kern";
    line-height: 1.625;
}
.about-skill{
    display: grid;
    row-gap: 1.5rem;
    column-gap: 2rem;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
}

@media screen and (min-width: 768px) {
    .about-skill{
        grid-template-columns: repeat(3, minmax(0px, 1fr));
    }
}
@media screen and (max-width: 270px) {
    .about-skill{
        grid-template-columns: repeat(2, minmax(0px, 1fr));
    }
}
.about-skill-label{
    font-size: 1.25rem;
    font-style: normal;
    letter-spacing: -0.05em;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 1rem;
}
.about-wrap{
    font-size: 0.875rem;
}
.about-wrap__list{
    --wrap-x-spacing: calc(0.25rem/2);
    --wrap-y-spacing: calc(0.25rem/2);
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 0.5rem;
    list-style-type: none;
    padding: 0px;
    margin: calc(var(--wrap-y-spacing) * -1) calc(var(--wrap-x-spacing) * -1);
}
.about-wrap__list>*:not(style){
    margin: var(--wrap-y-spacing) var(--wrap-x-spacing);
}
.about-wrap__listitem{
    display: flex;
    align-items: flex-start;
}
li{
    text-align: -webkit-match-parent;
}
.about-wrap__stack{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
    width: 110px;
}
@media screen and (min-width: 768px){
    .about-wrap__stack{
        width: 120px;
    }
}
.about-wrap__stack > :not(style) ~ :not(style){
    margin-top: 0px;
    margin-inline: 0.5rem 0px;
    margin-bottom: 0px;
}
.about-icon{
    width: 1rem;
    height: 1rem;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: currentColor;
    vertical-align: middle;
    fill: currentColor;
}


/* .about-award{
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
}
.about-award-img{
    width: 150px;
    height: 120px;
    border-radius: 20px;
    cursor: pointer;
}
.about-award-text{
    width: 72%;
    margin-right: 8px;
}
.about-award-title{
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
} */


/* Product List Styling */
.pl{
    padding: 20px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.pl-text{
    width: 65%;
}
.pl-title{
    font-size: 50px;
    font-weight: 600;
}
.pl-desc{
    margin: 20px 0px;
}
.pl-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* Product styling */
.p{
    width: 30%;
    height: 40vh;
    margin: 20px 10px;
    border: 2px solid rgba(243, 242, 242);
    cursor: pointer;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
}
.p-browser{
    background-color: rgba(243, 242, 242);
    height: 20px;
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
}
.p-circle{
    background-color: white;
    height: 6px;
    width: 6px;
    margin: 2px;
    border-radius: 60%;
}
.p-img{
    width: 100%;
    transition: all 10s ease;
}
.p:hover .p-img{
    transform: translateY(-100%);
}

/* Project Styling */
.project-container{
    width: 100%;
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: 56rem;
    display: grid;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-bottom: 1rem;
}
.project-stack{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.projectList-stack{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.project-wrapper-section{
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.project-heading{
    font-weight: 800;
    display: block;
}
.project-text{
    color: #4a5568;
}
.css-p1{
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
}

@media screen and (min-width: 640px){
    .css-p1 {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
    }
}

.project-linkBox{
    position: relative;
    background-color: rgba(255, 255, 255, 0.04);
    overflow: hidden;
    border-radius: 0.375rem;
    transition-duration: 300ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}
.project-linkBox:hover, .project-linkBox[data-hover]{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 100;
}
.__project-aspect-ratio{
    position: relative;
}
.__project-aspect-ratio::before{
    height: 0px;
    content: "";
    display: block;
    padding-bottom: 56.25%;
}
.project-detail-stack{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    background-color: rgba(0, 0, 0, 0.64);
    inset: 0px;
    opacity: 0;
    position: absolute;
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition-duration: 200ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.39, .575, .565, 1)
}
.project-detail-stack:hover{
    opacity: 100;
}
.project-detail-heading{
    font-family: '__Inter_0ec1f4','__Inter_Fallback_0ec1f4';
    font-weight: 800;
    letter-spacing: -0.05em;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.2;
    color: #fff;
}
.project-detail-text{
    line-height: 1.625;
    color: #fff;
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--nico-line-clamp);
    --nico-line-clamp:2;
    text-align: center;
}
.project-wrap__list{
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    list-style-type: none;
    gap: 0.5rem;
    padding: 0px;
}
.project-wrap__listitem{
    display: flex;
    align-items: flex-start;
}
.css-p2{
    display: inline-flex;
    vertical-align: top;
    -webkit-box-align: center;
    align-items: center;
    max-width: 100%;
    font-weight: 500;
    line-height: 1.2;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --nico-tag-color: #1a202c;
    --nico-tag-bg: #edf2f7;
    --nico-tag-shadow: #1A202C;
    color: var(--nico-tag-color);
    background: var(--nico-tag-bg);
    border-radius: 0.375rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    font-size: 0.75rem;
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;

}

/* Contact Styling */
._contact-css{
    width: 100%;
    -webkit-margin-start: auto;
    -webkit-margin-end: auto;
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: 56rem;
    display: grid;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    position: relative;
}
.contact-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
}
.contact-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px;
    text-align: center;
    gap: 1rem;
}
.contact-right{
    flex: 1;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* align-items: center; */
}
.contact-title{
    font-size: 50px;
    font-weight: 800;
}
.contact-title-desc{
    width: 90%;
    font-weight: 600;
}
.contact-info-item{
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 300;
}
.contact-icon{
    width: 80px;
    height: 30px;
    margin-right: 20px;
}
.contact-right-desc{
    font-weight: 400;
    color: #8a91a1;
}
.form-row{
    margin-top: 10px;
    width: -webkit-fill-available;
    margin-left: 0px;
}
.form-row>[class*=col-]{
    padding-left: 0px;
}
.form-group{
    margin: 0;
}
.label-input{
    font-size: 14px;
    font-weight: 500;
}
.formContact{ 
    margin-top: 10px;
} 
.formContact span{
    color: red;
}
.form-textarea{
    /* padding: 0px 5px 0px 5px; */
    margin-bottom: 10px;
}
.form-textarea #message{
    margin: 0;
}
/* .form-control {
    --select-bg: #2d3748;
} */
.form-control option{
    background: var(--select-bg);
    font-size: 12px;
}
.formContact input{
    width: 100%;
    height: 40px;
    border: none;
    border: 1px solid #b9b7b7;
    font-size: 14px;
    padding-left: 10px;
    border-radius: 5px;
    outline: transparent solid 2px
}
.formContact textarea{
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid #b9b7b7;
}
.formContact button{
    border: none;
    background-color: #000;
    padding: 15px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    border-radius: 7px;
    width: 150px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    display: none;
}
.contact-map{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
}


/* Toggle Styling */
.toggle{
    width: 50px;
    height: 25px;
    border-radius: 20px;
    border:1px solid rgb(192, 189, 189);
    position: fixed;
    top: 27px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}
.toggle-icon{
    width: 15px;
    height: 15px;
}
.toggle-button{
    width: 20px;
    height: 20px;
    background-color: #999;
    border-radius: 50%;
    position: absolute; 
}
.__toggleTab{
    width: 50px;
    height: 25px;
    position: fixed;
    top: 26px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}
.__toggleTab-icon{
    width: 15px;
    height: 15px;
}


/* Styling Bottom Tab Component */
.__mobile-navbar{
    background: rgba(216, 216, 216, 0.64);
    backdrop-filter: blur(9px);
    width: fit-content;
    left: 50%;
    transform: translate(-50%);
    border-radius: 100px;
    bottom: 0;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.__mobileIconText{
    font-size: 13px;
    color: #000;
    text-align: center;
    font-weight: 600;
}
.__toggleMobile{
    display:grid;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    width: 100px;
    background: transparent;
    color: #000;
}
.__toggleMobile.active{
    color: #000;
    text-decoration: none;
}
.__toggleMobile:hover{
    color: #000;
    text-decoration: none;
}
.__toggleMobile-icon{
    width: 3em;
    text-align: center;
    justify-content: center;
    font-size: 16px;
}
.__toggleMobile-icon:hover{
    transform: scale(1.04);
}

[type=reset], [type=submit], button, html, [type=button]:focus,
[type=reset], [type=submit], button, html, [type=button]:active{
    outline: none;
    border: none;
}
/* styling animasi modal bottomtab */
.modal.fade .modal-dialog{
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0; /*Hapus Margin default*/
    border-radius: 10px 10px 0 0; /*memberikan sudut atas modal melengkung*/
}
.modal.show .modal-dialog{
    transform: translateY(0); /*Modal Muncul dari bawah */
}
.modal-backdrop{
    display: none;
}
.modal-content{
    border-radius: 10px 10px 0 0;
}
.modal-header{
    border-bottom: none;
}
.modal-body{
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    -webkit-box-flex: 1;
    flex: 1 1 0%;
    overflow: auto;
}
.modal-stack{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}
.modal-link{
    text-align: center;
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: transparent solid 2px;
    line-height: 1.2;
    border-radius: 0.375rem;
    height: 2.5rem;
    min-width: 2.5rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    font-size: 0.875rem;
    width: 100%;
    font-weight: 400;
    text-decoration: none;
    color: #000;
}
.modal-link:hover{
    text-decoration: none;
    color: inherit;
}

/* Footer Styling */
.footer{
    padding: 50px 100px 30px 100px;
    text-align: center;
}
.footer-icon__button .icon-button__link{
    color: #718096;
}
.footer-icon{
    width: 3em;
}
.footer-desc__made a{
    color: #2756a3;
    font-weight: 500;
}
.textChange{
    font-size: 15px;
}
.footer-function{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.footer-function sub{
    font-weight: 600;
    color: #2756a3;
}
.f2{
    display:none;
}
.sub a{
    margin: 0px 10px 0px 10px;
}
/* Me-resize icon */
.icon-button__link .footer-icon:hover{
    transform: scale(1.04); 
}

/* Tidak Ada Postingan */
.noPost{
    text-align: center;
    /* background-color: tomato; */
    padding: 10px;
}
.noPost-Wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    flex-direction: column;
    min-height: calc(-60px + 100vh);
    row-gap: 2rem;
}
.noPost h2{
    font-weight: 800;
}
.noPost-Direction{
    font-size: 13px;
}
.noPost-TextDesc{
    font-size: 12px;
}

/* Styling Experience */
.experience-container{
    width: 100%;
    -webkit-margin-start: auto;
    margin-inline-start: auto;
    -webkit-margin-end: auto;
    margin-inline-end: auto;
    max-width: 56rem;
    display: grid;
    -webkit-padding-start: 1rem;
    padding-inline-start: 1rem;
    -webkit-padding-end: 1rem;
    padding-inline-end: 1rem;
    padding-bottom: 1rem;
    font-size: 15px;
    /* padding: 30px 15px 30px 15px;
    margin: 0px 230px 0px 230px; */
}
.experience-stack{
    display: flex;
    flex-direction: column;
}
.wrapper-section{
    text-align: center;
}
.experience-heading{
    font-weight: 800;
    display: block;
}
.experience-text{
    color: #4a5568;
}
.experience-textList{
    margin: 0rem 0rem 1rem 0.5rem;
    list-style-type: none;
    text-align: justify;
}
.experience-stack ul{
    padding: 0px;
}
.experience-subTrack{
    display: flex;
    align-items: center;
}
.experience-svg{
    width: 1em;
    height: 1em;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: #2756a3;
    margin-right: 0.5rem;
}
.experience-link{
    border-bottom: 2px dotted;
    font-size: 13px;
    color: #2756a3;
    font-weight: 520;
}
.experience-link:hover{
    text-decoration: none;
}
.loadData{
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.3rem;
}
.letter{
    display: inline-block;
    font-size: 1em;
    animation: bounce 1s ease-in-out infinite;
    transform-origin: bottom;
}
@keyframes bounce{
    0%, 80%, 100%{
        transform: translateY(0px);
    }
    30%{
        transform: translateY(-5px);
    }
    60%{
        transform: translateY(15px);
    }
}
.letter:nth-child(1){
    animation-delay: 0.1s;
}
.letter:nth-child(2){
    animation-delay: 0.2s;
}
.letter:nth-child(3){
    animation-delay: 0.3s;
}
.letter:nth-child(4){
    animation-delay: 0.4s;
}
.letter:nth-child(5){
    animation-delay: 0.5s;
}
.letter:nth-child(6){
    animation-delay: 0.6s;
}
.letter:nth-child(7){
    animation-delay: 0.7s;
}
.letter:nth-child(8){
    animation-delay: 0.8s;
}
.letter:nth-child(9){
    animation-delay: 0.9s;
}

/* Responsive objective for screen having width max 400px */
@media only screen and (max-width: 400px) {
    .__toggleMobile{
        width: 75px;
    }
    .__mobileIconText{
        font-size: 11px;
    }
    .__my-button-desc{
        width: 130px;
        font-size: 14px;
    }
}
/* Responsive styling - Max 480px - Mobile*/
@media screen and (max-width: 480px) {
    main{
        padding-top: 20px;
    }
    .my-name{
        font-size: 2.25rem;
        margin-bottom: 1rem;
    }
    /* Navbar */
    .nav-item .nav-link{
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .navbar{
        display: none;
        position: fixed;
        top: 5;
        width: 100%;
    }
    .navbar-collapse{
        overflow: hidden;
        transition: height 0.5s ease;
    }
    .portfolio-text{
        font-size: 13px;
    }
    /* contact */
    .contact-bg{
        display: none;
    }
    .contact-wrapper{
        flex-direction: column;
    }
    .contact-left{
        margin-top: 0 !important;
    }
    .contact-title{
        width: 100%;
        font-size: 35px;
        text-align: center;
    }
    .contact-title-desc{
        width: 100%;
        font-weight: normal;
        color: #8a91a1;
        margin-top: 1.5rem;
    }
    .text-justify{
        font-size: 15px;
    }
    .formContact{
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
    }
    .formContact input{
        width: 100%;
        height: 40px;
        margin-left: 0;
    }
    .formContact button{
        margin-bottom: 10px;
    }
    /* Footer */
    .footer-icon__button{
        /* width: 3em; */
        display: flex;
        /* margin-left: 2em; */
        align-items: center;
        justify-content: center;
    }
    .textChange{
        font-size: 13px;
    }
    .f1{
        display: none;
    }
    .f2{
        display: block;
    }
    .footer{
        padding: 20px 0px 80px 0px;
    }
    /* toggle */
    .toggle{
        display: none;
    }
    .__toggleTab{
        display: none;
    }
    .__mobileIconText{
        font-size: 12px;
    }
    /* experience */
    .experience-container{
        margin: 0px
    }
    /* project */
    .project-container{
        padding-top: 70px;
    }
}

/* Responsive styling for small tablet (potret) */
@media (min-width: 481px) and (max-width: 768px) {
    /* Navbar */
    .navbar{
        display: none;
    }
    /* about */
    .my-left-profil{
        padding: 10px 10px 0px 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }
    .my-name{
        font-size: 40px;
        margin-bottom: 2rem;
    }
    .hr-pembatas{
        display:block;
        margin: 50px 120px 0px 120px;
    }
    .my-title{
        height: 25px;
    }
    .my-title-wrapper{
        height: 50%;
    }
    .my-title-item{
        font-size: 20px;
        align-items: center;
        justify-content: center;
    }
    .my-pict{
        margin-bottom: 2rem;
    }
    .my-desc-wrapper{
        font-size: 14px;
    }
    .noPost-textDesc{
        padding: 120px 20px 120px 20px;
    }
    .about{
        flex-direction:column;
        padding-top: 0 !important;
        display:grid;
    }
    .about-info{
        display: grid;   
    }
    .about-skill{
        padding: 0px;
    }
    .about-left{
        width: 100%;
        padding-top: 0 !important;
        display: block;
    }
    .about-image{
        height: 30vh;
        left: 0px;
        border-radius: 5px;
        width: 100%;
    }
    .about-direction{
        padding: 0px;
    }
    .about-direction p{
        font-size: 17px;
        width: 100%;
    }
    .about-desc{
        font-size: 15px;
        width: 100%;
    }
    .about-desc .interest{
        margin-top: 10px;
    }
    .about-sub{
        margin: 10px 0px;
    }
    .about-title{
        display: none;
    }
    .about-end{
        padding: 0px;
        font-size: 14px;
    }
    /* footer */
    .footer{
        padding-bottom: 100px;
    }
    .footer-icon__button{
        /* width: 3em; */
        display: flex;
        /* margin-left: 2em; */
        align-items: center;
        justify-content: center;
    }
    .textChange{
        font-size: 13px;
    }
    .f1{
        display: block;
    }
    .f2{
        display: none;
    }
    /* toggle */
    .toggle{
        display: none;
    }
    .__toggleTab{
        display: none;
    }
    .__mobileIconText{
        font-size: 12px;
    }
}
/* Responsive styling for big tablet(Landskap) */
@media only screen and (min-width: 769px) and (max-width: 1024px){
    /* Navbar */
    .navbar-expand-lg .navbar-toggler{
        display: none;
    }
    .navbar-expand-lg .navbar-collapse{
        display: flex !important;
    }
    /* About */
    .__about-css{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .about{
        flex-direction:column;
        padding-top: 0 !important;
        display:grid;
    }
    .about-info{
        display: grid; 
        margin-top: 20px;  
    }
    .about-skill{
        padding: 0px;
        margin: 0px;
    }
    .about-left{
        width: 100%;
        padding-top: 0 !important;
        display: block;
    }
    .about-image{
        height: 30vh;
        left: 0px;
        border-radius: 5px;
        width: 100%;
    }
    .about-direction{
        padding: 0px;
    }
    .about-direction p{
        font-size: 17px;
        width: 100%;
    }
    .about-desc{
        font-size: 15px;
        width: 100%;
    }
    .about-desc .interest{
        margin-top: 10px;
    }
    .about-sub{
        margin: 10px 0px;
    }
    .about-title{
        display: none;
    }
    .about-end{
        padding: 0px;
        font-size: 14px;
    }
    .noPost-textDesc{
        padding: 400px 20px 400px 20px;
    }
    .experience-container{
        margin: 0px 150px 0px 150px;
        padding: 30px 0px 30px 0px;
    }
    /* Footer */
    .footer-icon__button{
        /* width: 3em; */
        place-content: left;
        /* margin-left: 10em; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .textChange{
        font-size: 13px;
    }
    .f1{
        display: block;
    }
    .f2{
        display: none;
    }
    .footer-text{
        margin-bottom: 0px;
    }
    /* BottomTab */
    .css-bottom{
        display: none;
    }
    /* toggle */
    .__toggleTab{
        right: 30px;
    }
    .toggle{
        display: none;
    }
}

@media screen and (min-width: 1025px){
    .toggle{
        right: 60px;
    }
    .css-bottom{
        display: none;
    }
    .__toggleTab{
        display: none;
    }
}